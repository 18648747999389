import axios from "axios";
import store from "../store/index.js";

/**
 * here we have the base url for all http requests
 * that will be made to the GeroCare App
 */
export const url = `${store.getters.gcurl}/api/crm`;

/**
 * default headers for http request
 */
const key = process.env.VUE_APP_API_SECRET_KEY;
export const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-CRM-Api-Key": key,
};

/**
 * Create new axios instance
 *
 * @return void
 */
const instance = axios.create({
  baseURL: url,
  headers: headers,
});

/**
 * Add a response interceptor
 *
 * @return void
 */
instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.message === "Network Error") {
      return Promise.reject(error.message);
    }

    if (error.response.data instanceof Array) {
      return Promise.reject(error.response.data[0]);
    } else {
      return Promise.reject(error.response.data);
    }
  }
);

/**
 * export axios instance as http
 */
export const gerohttp = instance;
