<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-info mb-2 mr-2"
              data-toggle="modal"
              data-target="#addModal"
            >
              <i class="fa fa-plus"></i>
            </button>

            <button
              type="button"
              class="btn btn-primary mb-2 mr-2"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive mb-4 mt-4">
              <table
                id="add_elderly_calls"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Sponsor Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Update</th>
                    <th>Date to retry</th>
                    <th>Created by</th>
                    <th>Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="call in elderlyCalls" :key="call.id">
                    <td>{{ call.sponsor_name }}</td>
                    <td>{{ call.sponsor_email }}</td>
                    <td>{{ call.sponsor_phone }}</td>
                    <td>
                      <span
                        v-if="call.status == 'responded'"
                        class="btn btn-success"
                        >Responded</span
                      >
                      <span
                        v-if="call.status == 'unreachable'"
                        class="btn btn-info"
                        >Unreachable</span
                      >
                      <span v-if="call.status == 'retry'" class="btn btn-dark"
                        >Retry</span
                      >
                      <span
                        v-if="call.status == 'declined'"
                        class="btn btn-danger"
                        >Declined</span
                      >
                    </td>
                    <td>{{ call.update }}</td>
                    <td>
                      <span v-if="call.date_to_retry != null">{{
                        formatDate(call.date_to_retry)
                      }}</span>
                    </td>
                    <td>
                      {{ call.user.firstname || "" }}
                      {{ call.user.lastname || "" }}
                    </td>
                    <td>{{ formatDate(call.created_at) }}</td>
                    <td class="d-flex">
                      <button
                        @click.prevent="showDelete(call.id)"
                        class="btn btn-sm m-1 btn-outline-danger"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <div
      class="modal fade"
      id="addModal"
      role="dialog"
      aria-labelledby="addModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>New Call To Add Elderly</p>
                </div>
                <form id="addModalTitle">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalName">Sponsor Name</label>
                        <Select2
                          style="width: 100%"
                          v-model="sponsor"
                          :options="sponsorNames"
                          @change="sponsorSelected()"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="addModalEmail">Email </label>
                        <input
                          type="email"
                          id="addModalEmail"
                          class="form-control"
                          readonly
                          v-model="selectedSponsor.email"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="addModalPhone">Phone Number </label>
                        <input
                          type="text"
                          id="addModalPhone"
                          class="form-control"
                          readonly
                          v-model="selectedSponsor.phone"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalNote">Update </label>
                        <textarea
                          class="form-control"
                          id="addModalNote"
                          rows="3"
                          v-model="newAddElderlyCall.update"
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                      <p>Status</p>
                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-success"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="responded"
                            v-model="newAddElderlyCall.status"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">Responded</span>
                        </label>
                      </div>

                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-info"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="unreachable"
                            v-model="newAddElderlyCall.status"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">Unreachable</span>
                        </label>
                      </div>

                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-dark"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="retry"
                            v-model="newAddElderlyCall.status"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">Retry</span>
                        </label>
                      </div>

                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-danger"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            v-model="newAddElderlyCall.status"
                            value="declined"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">Declined</span>
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                      <div class="form-group">
                        <label for="date_to_retry">Date to retry</label>
                        <b-form-datepicker
                          :min="today"
                          id="date_to_retry"
                          v-model="newAddElderlyCall.date_to_retry"
                          class="mb-2"
                        ></b-form-datepicker>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>

            <button
              id="btn-add"
              @click.prevent="saveNewAddElderlyCall()"
              class="btn btn-success"
              :disabled="loadingNew"
            >
              <b-spinner small v-if="loadingNew"></b-spinner>
              <span v-else>Save <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Modal -->
    <div
      class="modal fade"
      id="searchModal"
      role="dialog"
      aria-labelledby="searchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Date Search</label>
                  <select class="form-control" v-model="search" id="dateSearch">
                    <option value="all">All</option>
                    <option value="thisweek">This Week (From Monday)</option>
                    <option value="pastweek">Past Week (Last 7 days)</option>
                    <option value="lastmonth">Last Month</option>
                    <option value="thismonth">This Month</option>
                    <option value="thisyear">This Year</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                <div class="form-group" v-if="search == 'custom'">
                  <label>Select a range</label>
                  <div class="input-group">
                    <flat-pickr
                      v-model="date"
                      :config="config"
                      class="form-control"
                      placeholder="Select date"
                      @on-close="handlePicker()"
                      name="date"
                    >
                    </flat-pickr>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSearch()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete  -->
    <div
      class="modal fade"
      id="deleteModal"
      role="dialog"
      aria-labelledby="editContactModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p class="text-danger">
                    Are you sure you want to delete this Call Record?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">Cancel</button>

            <button
              @click.prevent="completeDelete()"
              class="btn btn-danger text-white"
              :disabled="loadingDelete"
            >
              <b-spinner small v-if="loadingDelete"></b-spinner>
              <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "v-select2-component";
import $ from "jquery";
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { ProspectCall } from "@/models/prospect.js";
import { DatePickerConfig } from "@/models/datepicker.js";

var moment = require("moment");

export default {
  name: "AddElderlyCalls",
  components: {
    Select2,
  },

  data() {
    return {
      loading: false,
      elderlyCalls: [],

      search: "thismonth",
      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",

      sponsor: "",
      sponsors: {},
      sponsorNames: [],
      selectedSponsor: {},

      today: moment().toDate(),

      loadingNew: false,
      newAddElderlyCall: new ProspectCall(),

      loadingDelete: false,
      deleteAddElderlyCall: "",
    };
  },

  mounted() {
    this.fetchSponsors();
    this.fetchCallRecords();
  },

  methods: {
    initTable() {
      var dt = $("#add_elderly_calls").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([1, 6, 7]).visible(false);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchCallRecords();
        $("#searchModal").modal("hide");
      }
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    fetchSponsors() {
      gerohttp
        .get(geroendpoints.FETCH_SPONSORS_WITHOUT_ELDERLY)
        .then((response) => {
          this.loading = false;
          this.sponsors = response;
          this.sponsorNames = this.sponsors.map((x) => {
            return x.name;
          });
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    fetchCallRecords() {
      this.loading = true;
      http
        .get(endpoints.ALL_ADD_ELDERLY_CALLS, {
          params: {
            search: this.search,
            customStart: this.customStart,
            customEnd: this.customEnd,
          },
        })
        .then((response) => {
          this.loading = false;
          this.elderlyCalls = response;
          setTimeout(() => {
            this.initTable();
          }, 500);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    sponsorSelected() {
      this.selectedSponsor = this.sponsors.find((x) => {
        return x.name == this.sponsor;
      });
    },

    saveNewAddElderlyCall() {
      if (this.sponsor == "" || this.newAddElderlyCall.status == "") {
        this.$toast.error("Missing input fields");
      } else {
        this.loadingNew = true;
        http
          .post(endpoints.NEW_ADD_ELDERLY_CALLS, {
            sponsor_id: this.selectedSponsor.id,
            sponsor_name: this.selectedSponsor.name,
            sponsor_email: this.selectedSponsor.email,
            sponsor_phone: this.selectedSponsor.phone,
            status: this.newAddElderlyCall.status,
            update: this.newAddElderlyCall.update,
            date_to_retry: this.newAddElderlyCall.date_to_retry,
          })
          .then((response) => {
            this.loadingNew = false;
            this.$toast.success(response);
            $("#addModal").modal("hide");
            this.newAddElderlyCall = new ProspectCall();
            this.fetchCallRecords();
          })
          .catch((error) => {
            this.loadingNew = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    showDelete(x) {
      this.deleteAddElderlyCall = x;
      $("#deleteModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(
          endpoints.DELETE_ADD_ELDERLY_CALL.replace(
            ":id",
            this.deleteAddElderlyCall
          )
        )
        .then((response) => {
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.$toast.success(response);
          this.fetchCallRecords();
        })
        .catch((error) => {
          this.loadingDelete = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/tables/table-basic.css"></style>
