var moment = require("moment");

export class DatePickerConfig {
  constructor() {
    (this.mode = "range"),
      (this.altInput = true),
      (this.altFormat = "F j, Y"),
      (this.dateFormat = "Y-m-d"),
      (this.maxDate = moment().toDate());
  }
}
