export class Prospect {
  constructor() {
    (this.name = ""),
      (this.email = ""),
      (this.phone = ""),
      (this.location = ""),
      (this.note = "");
  }
}

export class ProspectCall {
  constructor() {
    (this.status = ""), (this.update = ""), (this.date_to_retry = "");
  }
}
export class CaregiverReport {
  constructor() {
    (this.name = ""), (this.email = ""), (this.phone = ""), (this.remark = "");
  }
}
